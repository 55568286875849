<!--
 * @Description:
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-21 17:18:23
 * @LastEditors: 康蔚明
 * @LastEditTime: 2020-09-28 16:16:21
-->
<template>
  <div class="section">
    <div class="section-title">
      <div class="main-title">
        <p>学员心声</p>
      </div>
      <div class="sub-title">深耕执业药师继续教育 全国25w+名学员口碑见证</div>
    </div>
    <div class="section-voice">
      <div class="swiper-container" id="voiceSwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item,index) in arrData" :key="index">
            <div class="voice-item">
              <img class="user-header" src="https://renminyixue-prod.oss-cn-qingdao.aliyuncs.com/pu/zrlpa/default-avatar.png" />
              <div class="voice-content">
                <div class="voice-wrapper">
                  <div class="normal-wrapper">
                    <div class="voice-title">
                      <p>{{item.userName}}</p>
                      <Tag color="#1890FF" type="border">{{item.userMajor}}</Tag>
                    </div>
                    <div class="voice-msg">{{item.userMsg}}</div>
                  </div>
                  <div class="hover-wrapper">
                    <div class="voice-msg">{{item.userMsg}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="voice-pagination"></div>
    </div>
  </div>
</template>

<script>

import { arrData } from './data';
import maleAvatar from '@/assets/image/common/male-avatar.png';
import femaleAvatar from '@/assets/image/common/female-avatar.png';
export default {
  name: 'VoiceSection',
  components: {
  },
  props: {},
  data () {
    return {
      maleAvatar,
      femaleAvatar,
      arrData
    };
  },
  computed: {},
  watch: {},
  filters: {},
  created () {
  },
  mounted () {
    const mySwiper = new Swiper('#voiceSwiper', {
      // autoplay: 3000,
      slidesPerView: 3,
      slidesPerColumn: 2,
      spaceBetween: 30,
      pagination: '.voice-pagination',
      paginationClickable: true
    });

    // 移入暂停，移出滚动
    $('#voiceSwiper').hover(function() {
      mySwiper.stopAutoplay()
    }, function() {
      mySwiper.startAutoplay()
    })
  },
  methods: {

  }
}
</script>
<style lang='less' scoped>
@import '../section.less';

.section-voice {
  padding: 24px 24px 54px;
  background-color: #fff;
  .swiper-container {
    height: 362px;
    .swiper-wrapper {
      .swiper-slide {
        height: 166px;
        .voice-item {
          height: 166px;
          padding-left: 33px;
          position: relative;

          .user-header {
            width: 72px;
            height: 72px;
            border: 3px solid #fff;
            box-shadow: 0px 3px 6px 0px rgba(102, 102, 102, 0.16);
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 46px;
            z-index: 2;
          }
          .voice-content {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            cursor: pointer;
            overflow-y: hidden;

            &:hover {
              .voice-wrapper {
                margin-top: -166px;
              }
            }

            .voice-wrapper {
              transition: all 0.5s;
              overflow: hidden;

              .normal-wrapper {
                position: relative;
                padding: 20px 18px 20px 48px;
                height: 166px;
                overflow: hidden;
                border: 1px solid #ededed;

                .voice-title {
                  display: flex;
                  align-items: center;
                  p {
                    font-size: 16px;
                    padding-right: 6px;
                  }
                }
                .voice-msg {
                  margin-top: 10px;
                  line-height: 22px;
                  color: #666;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 4;
                  overflow: hidden;
                }
              }

              .hover-wrapper {
                display: flex;
                align-items: center;
                width: 100%;
                height: 166px;
                line-height: 1.64;
                padding: 18px 18px 35px 48px;
                background: #315884;
                border: 1px solid #315884;
                font-size: 16px;
                box-shadow: 0px 6px 10px 0px rgba(102,102,102,0.16);

                .voice-msg {
                  line-height: 22px;
                  color: #666;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 6;
                  color: #fff;
                  overflow: hidden;
                }
              }
            }
          }
        }
      }
    }
  }
  .voice-pagination {
    position: absolute;
    left: 0;
    bottom: 22px;
    width: 100%;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    /deep/.swiper-pagination-bullet {
      display: block;
      width: 14px;
      height: 6px;
      margin: 0 12px;
      opacity: 1;
      background: #ededed;
      border-radius: 3px;
      transition: 0.2s;
      &.swiper-pagination-bullet-active {
        width: 30px;
        background: @main-color;
      }
    }
  }
}
</style>
