<template>
  <div class="section">
    <div class="section-title">
      <div class="main-title red">
        <p style="color: #f26158">
          党政建设
        </p>
      </div>
    </div>
    <div class="section-government">
      <div v-for="item in newsList" :key="item.newsId" class="government-item"
           @click="goPage(`/news/news-info?newsId=${item.newsId}`)">
        <div class="item-header">
          {{ item.newsTitle }}
        </div>
        <div class="item-content">
          <div>
            <!--限制字数 64字-->
            <a class="content">{{ item.newsSecondTitle }}</a>
            <a class="more">查看详情></a>
          </div>
        </div>
      </div>
      <!--<div class="government-item" @click="goPage('http://www.xinhuanet.com/politics/2021-05/18/c_1127461010.htm')">
        <div class="item-header">
          新思想引领新征程·红色足迹｜精神永放光芒 初心照耀未来
        </div>
        <div class="item-content">
          <div>
            <a class="content">这里，古田会议永放光芒，《星星之火，可以燎原》等不朽篇章点亮了中国革命的灯塔……这里，10万多人参加红军，2.6万多人参加长征...</a>
            <a class="more">查看详情></a>
          </div>
        </div>
      </div>
      <div class="government-item" @click="goPage('http://www.xinhuanet.com/politics/leaders/2021-05/18/c_1127460350.htm')">
        <div class="item-header">
          潮涌珠江，听总书记与大湾区的故事
        </div>
        <div class="item-content">
          <div>
            <a class="content">推进粤港澳大湾区建设，是习近平总书记亲自谋划、亲自部署、亲自推动的重大国家战略。2008年到2020年，习近平同志先后9次到粤港...</a>
            <a class="more">查看详情></a>
          </div>
        </div>
      </div>
      -->
    </div>
  </div>
</template>
<script>
import { newsApi } from '@/api/news';
import { NEWS_TYPE_ENUM } from '@/constant/common';

export default {
  name: '',
  components: {},
  filters: {},
  mixins: [],
  props: {},
  data () {
    return {
      NEWS_TYPE_ENUM,
      newsList: []
    };
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
    this.queryNewsList();
  },
  beforeCreate () {
  },
  beforeMount () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  activated () {
  },
  methods: {
    // 查询党政建设资讯
    async queryNewsList () {
      try {
        const data = {
          pageNum: 1,
          pageSize: 3,
          newsTypeId: NEWS_TYPE_ENUM.GOVERNMENT.value
        };
        const result = await newsApi.queryNewsList(data);
        const list = result.data.list;
        list.forEach((item) => {
          if (item.newsSecondTitle.length > 64) {
            item.newsSecondTitle = `${item.newsSecondTitle.substring(0, 64)}...`;
          }
        });
        this.newsList = result.data.list;
      } catch (e) {

      }
    },
    goPage (src) {
      window.open(src);
    }
  }
};
</script>
<style lang="less" scoped>
@import "../section.less";

.section-government {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .government-item {
    cursor: pointer;
    width: 387px;
    height: 183px;
    background: #fffbfb;
    border: 1px solid #fffbfb;
    border-radius: 5px;
    display: flex;
    flex-direction: column;

    &:hover {
      border-color: #f26158;
    }

    .item-header {
      flex-shrink: 0;
      padding: 0 17px;
      height: 79px;
      border-bottom: 1px solid rgba(242, 97, 88, 0.2);
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      text-align: left;
      color: #f26158;
      line-height: 27px;
    }

    .item-content {
      flex: 1;
      padding: 0 17px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      line-height: 24px;
      letter-spacing: 1px;

      .content {
        color: #666666;
      }

      .more {
        color: #f26158;
      }
    }
  }
}
</style>
