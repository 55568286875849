<template>
  <div class="section">
    <div class="section-title">
      <div class="main-title">
        <p>通知公告</p>
      </div>
    </div>
    <div class="section-policy">
      <div class="left-part">
        <div class="banner-img">
          <a :href="`/news/news-info?newsId=${leftNews.newsId}`" :title="leftNews.newsTitle" target="_blank"
             :style="{background:`url(${leftNews.newsCover}) no-repeat center`,backgroundSize: '540px;'}"></a>
        </div>
        <div class="left-part-content">
          <h3 class="one-row">
            <a :href="`/news/news-info?newsId=${leftNews.newsId}`"
               :title="leftNews.newsTitle"
               target="_blank">{{ leftNews.newsTitle }}</a>
          </h3>
          <h4>{{ leftNews.publishTime }}</h4>
          <p class="content">
            <!--限制字数 180字-->
            <span>{{ leftNews.newsSecondTitle }}</span>
            <a class="more" :href="`/news/news-info?newsId=${leftNews.newsId}`" title="leftNews.newsTitle" target="_blank">查看详情></a>
          </p>
        </div>
      </div>
      <div class="right-part">
        <div v-for="item in newsList" :key="item.newsId" class="right-item">
          <div class="item-date">
            <b>{{ item.time }}</b>
            <p>{{ item.year }}</p>
          </div>
          <div class="item-text">
            <h3 class="one-row">
              <a :href="`/news/news-info?newsId=${item.newsId}`"
                 :title="item.newsTitle"
                 target="_blank">{{ item.newsTitle }}</a>
            </h3>
            <p class="two-row">
              {{ item.newsSecondTitle }}
            </p>
          </div>
        </div>
        <a class="more" :href="`/news/news-list?type=`">查看更多></a>
      </div>
    </div>
  </div>
</template>
<script>
import { newsApi } from '@/api/news';
import { NEWS_TYPE_ENUM } from '@/constant/common';
export default {
  name: '',
  components: {},
  filters: {},
  mixins: [],
  props: {},
  data () {
    return {
      NEWS_TYPE_ENUM,
      leftNews: {},
      newsList: []
    };
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
    this.queryNewsList();
  },
  beforeCreate () {
  },
  beforeMount () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  activated () {
  },
  methods: {
    async queryNewsList () {
      try {
        const data = {
          pageNum: 1,
          pageSize: 6,
          newsTypeId: NEWS_TYPE_ENUM.POLICY.value
        };
        const result = await newsApi.queryNewsList(data);
        const list = result.data.list;
        if (!this.lodash.isEmpty(list)) {
          this.leftNews = list[0];
          list.splice(0, 1);
          list.forEach((item) => {
            const publishTime = item.publishTime;
            if (publishTime) {
              item.year = publishTime.substring(0, 4);
              item.time = publishTime.substring(5, 10);
            }
          });
        }
        this.newsList = result.data.list;
      } catch (e) {

      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "../section.less";

.section-policy {
  width: 100%;
  padding: 15px 12px 15px;
  display: flex;

  .left-part {
    width: 540px;
    flex-shrink: 0;

    .banner-img {
      width: 540px;
      height: 300px;
      border-radius: 4px;

      > a {
        display: block;
        width: 540px;
        height: 300px;
        border-radius: 4px;
      }
    }

    .left-part-content {
      a {
        color: #000000;

        &:hover {
          color: @main-color;
        }
      }

      h3 {
        font-size: 16px;
        color: #000;
        font-weight: 700;
        margin-top: 15px;
      }

      h4 {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }

      .content {
        margin-top: 15px;
        font-size: 14px;
        color: #666;
        line-height: 24px;

        .more {
          color: @main-color;
        }
      }
    }
  }

  .right-part {
    width: 0;
    margin-left: 47px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .more {
      color: @main-color;
      align-self: flex-end;
    }

    .right-item {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .item-date {
        height: 76px;
        flex-shrink: 0;
        margin-right: 20px;
        text-align: right;
        padding-right: 10px;
        border-right: 1px solid #ededed;
        display: flex;
        flex-direction: column;

        b {
          font-size: 16px;
          color: #000000;
          line-height: 21px;
        }

        p {
          margin-top: auto;
          font-size: 14px;
          color: #999999;
          line-height: 21px;
        }
      }

      .item-text {
        flex: 1;
        width: 0;

        h3 {
          font-size: 16px;
          line-height: 22px;
          color: #000000;
          font-weight: 700;
        }

        a {
          color: #000000;

          &:hover {
            color: @main-color;
          }
        }

        p {
          margin-top: 8px;
          font-size: 14px;
          line-height: 24px;
          color: #666666;
        }
      }
    }
  }

}
</style>
